import React, { useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { Modal } from "bootstrap"
 
const Home = () => {
    const inputRef = useRef(null)
    const navigate = useNavigate()
    const [nama, setNama] = useState(null)
    const [photo, setPhoto] = useState(null)
    const [kodePeserta, setKodePeserta] = useState(null)
    const [totalPengunjung, setTotalPengunjung] = useState(0)
    const [pengunjung, setPengunjung] = useState([])
    const [namaSekolah, setNamaSekolah] = useState(null)
    const [camaba, setCamaba] = useState(0)
    const [statusUndian, setStatusUndian] = useState(0)
    const [isLogin, setIsLogin] = useState(0);
    const [data, setData] = useState([])
    let intervalId;
    var myHeaders = new Headers();
    


    const pageStyle = {
        backgroundImage: `url(${process.env.PUBLIC_URL}/back_6.png)`,
        minHeight: '100vh',
        padding: '20px',
        backgroundSize: 'contain',
        display: 'flex',
        flexDirection: 'column',
        backgroundRepeat:'no-repeat'
    };

    const marginRowStyle = {
        marginTop: '4rem',
    };

    const handleVideoPlay = () => {
        // inputRef.current.focus()
    };

    const fetchData = () => {
        myHeaders = new Headers();
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };    
        fetch("https://pameran.apps.unismuh.ac.id/peserta.php?aksi=peserta", requestOptions)
        .then(response => response.json())
        .then(result => {
        console.log(result);
        if (result.result === 'ok') {
            setData(result);
            console.log("awa",result);
            setTotalPengunjung(result.total);
            setPengunjung(result.masterdata)
            setCamaba(result.camaba)
            setStatusUndian(result.undian);
        } else {
            setTotalPengunjung(result.total);
            setCamaba(result.camaba)
            setStatusUndian(result.undian);
        }


        })
        .catch(error => console.log('error', error));



    }

    React.useEffect(() => {
        inputRef.current.focus()
        fetchData();
        setTimeout(() => {
        const video = document.getElementById('myVideo');
        if (video) {
            video.play();      
        }
        }, 3000);

        intervalId = setInterval(() => {
            fetchData();
            // inputRef.current.focus();
        }, 5000); // 5000 milliseconds = 5 seconds

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);    


    }, [])   

    const handleInputChange = async (event) => {
        inputRef.current.value = event.target.value
        if (inputRef.current.value.length >= 4) {
        try{

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
            
            var urlencoded = new URLSearchParams();
            urlencoded.append("aksi", "absen");
            urlencoded.append("kode_peserta", inputRef.current.value);
            
            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
            };
            
            fetch("https://pameran.apps.unismuh.ac.id/peserta.php", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log("peserta",result);            
                setNama(result.masterdata[0].nama_peserta);
                setPhoto(result.photo);            
                setKodePeserta(result.masterdata[0].kode_peserta);
                setNamaSekolah(result.masterdata[0].jabatan);

                let timerInterval;

                Swal.fire({
                    title: result.masterdata[0].nama_peserta,
                    text: result.masterdata[0].jabatan,
                    html: "Tutup dalam <b></b> detik",            
                    imageUrl: result.photo,
                    imageWidth: 200,
                    imageHeight: 260,
                    imageAlt: "Custom image",
                    backdrop:false,
                    allowOutsideClick: false,
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,        
                    icon:'success',
        
                    didOpen: () => {
                        Swal.showLoading();
                        console.log(photo);
                        const timer = Swal.getPopup().querySelector("b");
                        timerInterval = setInterval(() => {
                            timer.textContent = `${Math.floor(Swal.getTimerLeft()/1000)+1}`;
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                        // window.location.reload()
                    }
                });            

            })
            .catch(error => console.log('error', error)); 
        
            inputRef.current.value = ''
        } catch (error) {
            console.log(error);
        }

        
        }
    }
    const undian = () => {
        if (statusUndian > 0) {
            console.log("Test");
            navigate(`/undian`);
        }
    }
    const undianlocal = () => {
        if (statusUndian > 0) {
            console.log("Test");
            navigate(`/undianlokal`);
        }
    }
    const undianbanyak = () => {

        if (!isLogin) {
            clearInterval(intervalId);             
            Swal.fire({
                title: 'Login Required',
                input: 'text',
                inputLabel: 'Masukkan kode akses Anda',
                inputPlaceholder: 'ketik kode akses disini',
                showCancelButton: true,
                confirmButtonText: 'Login',
                showLoaderOnConfirm: true,
                preConfirm: (login) => {
                    return fetch(`https://pameran.apps.unismuh.ac.id/peserta.php?aksi=login&kode_peserta=${login}`, {
                        method: 'GET'
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Login failed');
                        }
                        return response.json();
                    })
                    .then(data => {
                        if (data.result === 'ok') {
                            navigate('/undianmulti')
                            return setIsLogin(1);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: data.pesan
                            });
                        }
                    })
                    .catch(error => {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Login failed!'
                        });
                    });
                    // You can also make an API call here to verify login
                },
                allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
                console.log("result",result);
                if (result.isConfirmed) {
                    console.log("isLogin",isLogin);
                    // if (result.value) {
                    //     navigate(`/undianmulti`);
                    // }
            

                    // Handle the login confirmation
                }
            });
        }

    }



    return (
        <>
            <div style={pageStyle} className="container-fluid">        
                <div className="row justify-content-end">
                    <div className="col-md-1 mb-2">
                        <button className='btn btn-primary w-100' onClick={()=>undianbanyak()}>Undian </button>                                
                    </div>
                    <div className="col-md-1 mb-2">
                        <form className="form-inline">
                            <input ref={inputRef} onInput={handleInputChange}
                                className="form-control"
                                aria-label="Search"
                            />                    
                        </form>            
                    </div>
                </div>

                <div className="row justify-content-end">
                    <div className="col-md-3 mb-3 mb-md-0">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Laki-laki / Perempuan</h5>
                                <div className='justify-content-end'>
                                    <h3 className="card-text" style={{fontSize:'4vw',fontStyle:'italic'}}>{`${data.laki_laki}`} / {`${data.perempuan}`}</h3>
                                </div>                               
                            </div>
                        </div>
                    </div>

                    <div className="col-md-2">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">REGISTRASI</h5>
                                <h3 className="card-text" style={{fontSize:'4vw',fontStyle:'italic'}}>{parseInt(totalPengunjung).toLocaleString()}</h3>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="row" style={{ flex: '1', marginBottom: 'auto', marginTop:'3vh' }}>
                    <div className="col-md-7 mb-3 mb-md-0">
                        <div className="card">
                            <div className="card-body">
                                <video
                                    id="myVideo"
                                    width="100%"
                                    height="100%"
                                    controls
                                    autoPlay
                                    loop
                                    onPlay={handleVideoPlay}
                                    muted
                                >
                                    <source src="./profil.mp4" type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-5">
                        {pengunjung && pengunjung.map((item,index)=>(
                        <div className="card mb-3 shadow-sm" key={index}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src={item.photo} alt="Profile" className="img-fluid" />
                                    </div>
                                    <div className="col-md-10 align-items-center">
                                        <h3 className="card-title">{item.nama_peserta}</h3>
                                        <p className="card-text" style={{fontSize:'1.5rem'}}>{item.jabatan}</p>
                                    </div>
                                </div>                

                            </div>
                        </div>
                        ))}                
                    </div>
                </div>

                <div className="row fixed-bottom" style={{ width: '100%', backgroundColor: 'white' }}>
                    <div className="col-md-12">
                        <marquee className="running-text">
                            Universitas Muhammadiyah (Unismuh) Makassar akan menyelenggarakan kegiatan akbar Launching Penerimaan Mahasiswa Baru (PMB) pada hari Kamis, 28 November 2024 - 
                        </marquee>
                    </div>
                </div>
            </div>
            <div id='loginModal'></div>
        </>
    );
};

export default Home;
